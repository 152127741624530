.dev-tree {
	padding: 10px 0 0 0;
	height: 100%;
	display: flex;
	flex-direction: column;

	//border-right: 1px solid #eee;
	.ant-tree {
		overflow-x: auto;

		.ant-tree-node-content-wrapper {
			min-width: 100px;
		}
	}

	.ant-input-affix-wrapper {
		svg {
			width: 1em !important;
			height: 1em !important;
		}
	}

	.ant-input {
		font-size: 14px;
	}

	.ant-input-search-icon::before {
		border-left: 0 none;
	}

	.site-tree-search-value {
		color: red;
	}
}

.dev-tree-search {
	padding-bottom: 20px;
	margin: 0 15px;
}

.ant-tree-list {
	// height: 100%;
	overflow-x: auto;

	& > div {
		height: 100%;
	}
}

.ant-tree {
	height: 100%;
}

.ant-tree-list-holder-inner {
	height: 100%;
}

@primary-color: #fd8820;