.location-modal {
	&.ant-modal {
		width: 80% !important;
		height: 80% !important;

		.ant-modal-content {
			height: 100%;
			display: flex;
			flex: 1;

			.ant-modal-body {
				display: flex;
				flex: 1;
				position: relative;
				padding: 0;

				.map-label-info {
					color: #ffffff;
				}

				.amap-content-body {
					color: #ffffff;
				}

				.selected-address-text {
					position: absolute;
					bottom: 60px;
					right: 10px;
					font-size: 16px;
					font-style: italic;
				}

				.modal-bottom-no {
					visibility: hidden;
				}
				.modal-bottom {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					display: flex;
					height: 60px;
					justify-content: space-around;
					align-items: center;
					background-color: rgba(240, 236, 229, 0.8);
					padding: 0 50px;
					z-index: 10000;

					&-left {
						display: flex;
						flex: 1;
						margin-right: 50px;

						input {
							height: 30px;
							border: 1px solid #999;
							margin-right: 5px;

							&::-webkit-input-placeholder {
								color: rgba(240, 236, 229, 0.9);
							}
						}
					}

					.pos-show-input {
						width: 300px;
						height: 30px;
						font-weight: bold;
					}
				}
			}
		}
	}
}

@primary-color: #fd8820;