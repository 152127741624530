.uni-config-page {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}
.uni-config-page-content {
	width: calc(100% - 40px);
	height: 100%;
	display: flex;
	.main-body {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.search-box {
			display: flex;
			justify-content: space-between;
			padding: 20px;
			.search-container {
				display: flex;
				height: 52px;
				align-items: center;
			}
		}
		.search-content,
		.search-btn {
			display: flex;
		}
		.title-search-item {
			display: flex;
			margin-right: 15px;
			align-items: center;
			.label {
				flex: none;
				margin-right: 8px;
			}
		}
		.search-box,
		.table-box {
			box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
			background: #fff;
		}
		.table-box {
			margin-top: 20px;
			flex: 1;
			height: calc(100% - 112px);
			overflow: hidden;
			.action-box {
				display: flex;
				height: 60px;
				align-items: center;
				padding: 0 25px;
			}
			.table-container {
				padding: 10px 20px 0 20px;
				width: 100%;
				height: calc(100% - 60px);
				.ant-table-thead > tr > th {
					background-color: #f2f5f5;
					font-weight: 400;
					height: 60px;
					line-height: 60px;
					padding: 0 16px;
					text-align: center;
				}
				.animate-loading {
					animation: loop 1.5s linear infinite;
					-webkit-animation: loop 1.5s linear infinite;
				}
				.error-temp-box {
					width: 100%;
					height: calc(100vh - 484px);
				}
			}
		}
	}
}
.text-align_center {
	text-align: center;
}
@keyframes loop {
	0% {
		transform: rotate(0deg);
	}
	0% {
		transform: rotate(-360deg);
	}
}

@primary-color: #fd8820;