.image-upload-wrapper {
	display: flex;
	& .form-images {
		width: 120px;
		height: 120px;
		background: rgba(67, 76, 97, 0.1);
		border: 1px solid rgba(135, 144, 167, 1);
		border-radius: 5px;
		margin-right: 18px;
		position: relative;

		& img {
			width: 100%;
			height: 100%;
		}

		& .image-empty {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
		}

		& .image-del-icon {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
			width: 40px;
			height: 40px;
			background: #fff;
			border-radius: 50%;
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		&:hover {
			& .image-del-icon {
				opacity: 0.8;
			}
		}
	}

	& .form-images-base64 {
		.avatar-uploader {
			.ant-upload.ant-upload-select.ant-upload-select-picture-card {
				width: 78px;
				height: 78px;
			}
		}
	}
}

@primary-color: #fd8820;