.company_base_people {
	position: relative;
	width: 100%;
	height: 100%;
	.__temp__page_table {
		overflow: hidden;
	}
}
// .company_server_manage {
// 	position: relative;
// 	width: 100%;
// 	height: 100%;
// 	.company_server_manage_detail {
// 		position: absolute;
// 		display: flex;
// 		width: calc(100% - 40px);
// 		height: calc(100% - 15px);
// 		z-index: 5;
// 		background-color: #ffffff;
// 		margin: 0 20px;
// 	}
// }
// .time_limit_content {
// 	width: 100%;
// 	display: flex;
// 	align-items: center;
// 	margin: 40px 0;
// 	padding-left: 70px;
// 	.ant-input-number {
// 		min-width: 180px;
// 		margin: 0 5px 0 15px;
// 	}
// 	.ant-select {
// 		margin-right: 30px;
// 	}
// 	.time_config_title {
// 		color: red;
// 	}
// }
// .base-manage-form {
// 	.ant-modal-body {
// 		display: flex;
// 		flex-direction: column;
// 	}
// 	.ant-modal-body::before {
// 		content: '基础信息';
// 		margin: -10px 0 8px 23px;
// 		padding-left: 8px;
// 		border-left: 4px solid var(--themeColor);
// 	}
// }
// .base-manage-check {
// 	.ant-row .ant-col-24:last-child::before {
// 		content: '审核信息';
// 		padding-left: 8px;
// 		border-left: 4px solid #00b792;
// 	}
// 	.ant-row .ant-col-24:last-child {
// 		.ant-form-item-label {
// 			margin-top: 15px;
// 		}
// 	}
// }
// .check-detail {
// 	.ant-row .ant-col-24:nth-last-child(2)::before {
// 		content: '审核信息';
// 		padding-left: 8px;
// 		border-left: 4px solid #00b792;
// 	}
// 	.ant-row .ant-col-24:nth-last-child(2) {
// 		.ant-form-item-label {
// 			margin-top: 15px;
// 		}
// 	}
// }
// .company-management {
// 	position: relative;
// 	.no-pass-list {
// 		position: absolute;
// 		right: 50px;
// 		color: red;
// 		border-bottom: 1px solid red;
// 		padding: 0 5px;
// 	}
// }
// .base_manage {
// 	width: 100%;
// 	height: 100%;
// }

@primary-color: #fd8820;