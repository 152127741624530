.wang-editor {
	.w-e-toolbar {
		// 设置菜单栏样式
		border: 1px solid #ccc;
	}
	.w-e-text-container {
		// 设置内容区域样式
		border: 1px solid #ccc;
	}
	.w-e-toolbar {
		flex-wrap: wrap;
	}
	.w-e-text ul li {
		list-style: square;
	}
	.w-e-text ol li {
		list-style: decimal;
	}
	.w-e-text i {
		font-style: italic;
	}
}

@primary-color: #fd8820;