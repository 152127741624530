.detail-content {
	position: relative;
	width: 100%;
	height: calc(100vh - 50px);
	padding: 5px 18px;
	.detail-line {
		background-image: url('../../static/images/message-line.png');
		width: 100%;
		height: 52px;
		background-position: 50% 0;
	}
	.detail-title {
		font-size: 18px;
		font-weight: bold;
		color: #ffffff;
		background: linear-gradient(0deg, #777d99 19.091796875%, #ffffff 99.31640625%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: 'QhcFont';
		margin-bottom: 30px;
		display: flex;
		justify-content: center;
	}
	.detail-desc-list {
		border-bottom: 1px solid #586585;
		.detail-item {
			padding-bottom: 18px;
			font-size: 14px;
			color: #7e89a3;
			.detail-label {
				width: 88px;
			}
			.detail-content {
				flex: 1;
			}
		}
	}

	.message-content {
		color: #7e89a3;
		padding-top: 25px;
		max-height: 450px;
		overflow-y: auto;
		line-height: 32px;
	}

	.footer-action {
		position: absolute;
		bottom: 50px;
		right: 100px;
	}
}

.loading-center {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
}

.message-drawer {
	.message-list {
		.message-item {
			padding: 15px;
			margin-bottom: 10px;
			height: 128px;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-bottom: 1px solid #ccc;
			.message-content {
				font-size: 14px;
				display: flex;
				flex-wrap: wrap;

				.message-content-inner {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
				.message-content-go {
					color: #6d95f1;
					cursor: pointer;
					display: flex;
					justify-content: flex-end;
					width: 100%;
				}
			}
			.message-desc {
				display: flex;
				justify-content: space-between;
				& span.desc-img {
					&.unread {
						width: 21px;
						height: 14px;
						position: relative;
						background: url('../../static/images/message-unread.png') no-repeat;
						background-size: contain;
						&::after {
							position: absolute;
							right: -4px;
							top: -4px;
							width: 8px;
							height: 8px;
							background-color: #e60012;
							border-radius: 50%;
							content: '';
						}
					}
					&.read {
						background: url('../../static/images/message-read.png') no-repeat;
						background-size: contain;
						width: 22px;
						height: 21px;
					}
				}
			}
		}
		.message-pagination {
			margin-top: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.ant-drawer-content-wrapper {
		top: 50px;
	}
	.ant-drawer-body {
		padding: 0;
		// background-color: rgba(31, 36, 47, 0.8);
	}

	.ant-drawer-content {
		// background-color: transparent;
	}

	.ant-drawer-header {
		padding-left: 15px;
		// background-color: transparent;
	}
}

.message-detail {
	.ant-drawer-content-wrapper {
		top: 50px;
	}
	.ant-drawer-body {
		padding: 0;
		// background-color: rgba(31, 36, 47, 0.3);
	}
}

@primary-color: #fd8820;