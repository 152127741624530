.iot-form {
	flex: 1;
	//display: flex;
	max-height: 552px;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.form-item-view {
		flex: 1;
		height: calc(100% - 52px);
		max-height: 568px;
		overflow: hidden;
		overflow-y: auto;
		padding: 0 24px;
	}
	.iot-form-item {
		margin-bottom: 15px;
		input::-webkit-input-placeholder {
			color: #7c8392;
		}
		.ant-select-selection-placeholder {
			color: #7c8392 !important;
			opacity: 1;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none !important;
			margin: 0;
		}
	}
	.iot-form-actions {
		padding: 0 24px;
		text-align: right;
		margin-top: 20px;
		button {
			margin-left: 20px;
		}
	}
}

@primary-color: #fd8820;