.iot-modal {
	.ant-modal-close {
		color: #fff;
	}
	.ant-modal-header {
		background: var(--themeColor);
		color: #fff;
		.ant-modal-title {
			color: #fff;
		}
	}
}

@primary-color: #fd8820;