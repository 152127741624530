.company_server_detail {
	display: flex;
	width: 100%;
	height: 100%;
	.title {
		border-left: 5px solid var(--themeColor);
		padding-left: 5px;
	}
	.require_icon::before {
		content: '*';
		color: red;
		padding-top: 3px;
	}
	.left {
		width: 65%;
		height: 100%;
		padding: 18px 0 40px 70px;
		overflow: auto;
		.example {
			display: flex;
			justify-content: center;
			width: 100%;
			align-items: center;
			height: 100%;
		}
		.base_content {
			margin-left: 10px;
		}
		.content_item {
			display: flex;
		}
		.item_info {
			width: 50%;
			display: flex;
			align-items: center;
			margin: 10px 0 15px 0;
		}
		.label {
			margin-right: 20px;
			width: 100px;
			white-space: nowrap;
		}
		.detail_info {
			width: 67%;
		}
		.file-item {
			cursor: pointer;
			color: var(--themeColor);
		}
		.file-delete-icon {
			display: flex;
		}
		.image_info {
			width: 80%;
			display: flex;
			align-items: center;
		}
		.fail {
			color: var(--themeColor);
		}
		.task-handler {
			display: flex;
		}
		.file_info {
			display: flex;
			// flex-direction: column;
			// background-color: pink;
		}
		.deal_file {
			display: flex;
			margin: 5px 40px 0 0;
		}
		.images {
			display: flex;
			.images_item {
				width: 100px;
				height: 120px;
				margin-right: 10px;
			}
		}
		.special_item {
			display: flex;
			align-items: flex-start;
			margin: 10px 0 15px 0;
		}
		.deal {
			margin-left: 10px;
		}
		.ant-checkbox-disabled .ant-checkbox-inner {
			background-color: #d9d9d9 !important;
		}
	}
	.right {
		width: 35%;
		height: 100%;
		padding: 18px 20px 0 50px;
		overflow-x: hidden;
		overflow-y: auto;

		border-left: 10px solid #e6e5e5;
		.history {
			margin-top: 10px;
		}
	}
	.submit-btn {
		display: flex;
		justify-content: center;
	}
	.company-video-title {
		display: flex;
	}
	.company-video-tip-ellipsis {
		max-width: 105px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
	}
}

@primary-color: #fd8820;