.app-page {
	position: relative;
	width: 100%;
	height: 100%;
	//background-image: url('./static/images/indexbgG.gif');
	background-color: #101628;
	background-size: 100% 100%;
	//padding-top: 20px;
}

.app-header {
	width: 100%;
	height: 174px;
	padding: 0 20px 80px 20px;
	background-size: 100% 174px;
	background-repeat: no-repeat;
	.__iot_header_opt2 {
		display: flex;
		position: fixed;
		align-items: center;
		right: 20px;
		top: 10px;
		color: var(--themeColor);
		// background-color: pink;
	}
	.__iot_header_logo {
		display: none;
	}
	.logo-title {
		width: 62px;
		height: 62px;
		// background-image: url('./static/images/title-logo.png');
		// background-size: 62px 62px;
	}
	.__iot_header_title {
		& > div {
			display: flex;
			flex-direction: row;
			align-items: center;
			span {
				display: inline-block;
				font-family: BigruixianBlackGBV10;
				//width: 800px;
				font-size: 44px;
				line-height: 55px;
				//background-image: linear-gradient(90deg, #7300FF 0%, #FFFFFF 0%);
				background-image: -webkit-linear-gradient(bottom, #06c29f 0%, #d8d8d8 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-align: center;
				margin-left: 10px;
			}
		}
	}
	.toggle-btn {
		cursor: pointer;
	}
	.opt-message {
		margin-right: 20px;
		cursor: pointer;
	}
	.__iot_header_opt {
		display: none;
	}

	//background-color: #042334;
	color: #ffffff;
}
.logout {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.__iot_header_opt3 {
	width: 100vw;
	height: 80px;
	background-image: url('./static/images/indexheaderbg.png');
	background-size: 100% 100px;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px;
	background-color: #042334;
	.__iot_header_opt3-left {
		display: flex;
		flex-direction: row;
		align-items: center;
		.aok-logo {
			width: 40px;
			height: 40px;
			// background-image: url('./static/images/title-logo.png');
			// background-size: 40px 40px;
			// background-repeat: no-repeat;
			cursor: pointer;
		}
		.aok-title {
			span {
				display: inline-block;
				//font-family: BigruixianBlackGBV10;
				//width: 800px;
				font-size: 24px;
				font-weight: bold;
				//line-height: 55px;
				//background-image: linear-gradient(90deg, #7300FF 0%, #FFFFFF 0%);
				background-image: -webkit-linear-gradient(bottom, #06c29f 0%, #d8d8d8 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-align: center;
				margin-left: 10px;
			}
		}
	}
	.__iot_header_opt3-right {
		display: flex;
		position: fixed;
		align-items: center;
		right: 20px;
		color: var(--themeColor);
		height: 80px;
		top: 0;
	}
}
.app-body {
	padding: 0;
	margin: 0;
	width: 100%;
	//height: calc(100% - 40px);
	height: 100%;
	.noPage {
		text-align: center;
		font-size: 18px;
		color: #ffffff;
	}
}
.app-header-logout {
	display: inline-block;
	width: 20px;
	height: 20px;
	cursor: pointer;
	margin-left: 10px;
	background-image: url('../src/static/images/exit.png');
	background-size: 100% 100%;
}
.app-header-nav {
	width: 23px;
	height: 24px;
	font-size: 19px;
	cursor: pointer;
	margin-right: 90px;
	margin-bottom: 5px;
	background-image: url('../src/static/images/big_screen.png');
	background-size: 100% 100%;
}
.exit_text {
	color: #ffffff;
	margin-left: 6px;
	font-size: 14px;
}
.iot-opt-view {
	display: flex;
	min-width: 140px;
	flex-direction: row;
	user-select: none;
	flex: 1;
	text-align: center;
	justify-content: center;
	overflow: hidden;
	span {
		color: var(--themeColor);
		margin: 0 5px;
		cursor: pointer;
		white-space: nowrap;
	}
	span:hover {
		opacity: 0.7;
	}
	span:active {
		opacity: 1;
	}
}
.ant-menu-item {
	user-select: none;
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: var(--themeColor);
	border-color: var(--themeColor);
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: var(--themeColor);
}
.ant-pagination-item-active a {
	color: var(--themeColor);
}
.ant-pagination-item-active {
	border-color: var(--themeColor);
}
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
	border-color: var(--themeColor);
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
	color: var(--themeColor);
}
.ant-tabs-tab {
	color: #333333;
	font-weight: 900;
}
.ant-tabs-tab-btn {
	color: #333333;
	font-weight: 900;
}
.ant-tabs-ink-bar {
	background-color: var(--themeColor);
	height: 3px !important;
}
.ant-tabs-nav {
	background-color: #fff;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
	margin-bottom: 0;
	padding: 0 20px;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: var(--themeColor);
}
.dev-tree {
	background-color: #fff;
}
.ant-btn-primary {
	background-color: var(--themeColor);
	border-color: var(--themeColor);
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
	background-color: var(--themeColor);
	border-color: var(--themeColor);
}
input:focus {
	border-color: var(--themeColor) !important;
}
.video-ctrl-title {
	font-size: 12px !important;
}
.iot-opt-view > .iot-opt-del {
	color: #dd2b2b;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
	background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
}

.iot_disabled {
	color: #111 !important;
	opacity: 0.7 !important;
	cursor: not-allowed;
	i {
		margin: 0 5px;
	}
	//span {
	//}
}
.ant-tree .ant-tree-treenode {
	height: 50px;
	align-items: center;
}
.ant-tree-node-content-wrapper {
	height: 50px;
	line-height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
// .ant-tree-treenode {
// 	width: 250px;
// }
.tree-data-line-title {
	line-height: 40px;
}
.tree-data-line {
	align-items: center;
}
.tree-data-line .tree-data-line-opt {
	top: 8px;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
	width: 3px;
	background-color: transparent;
	border-radius: 3px;
	height: 3px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background: #aab3af;
	//background: #337759;
}
.ant-tree-treenode {
	display: flex;
	.ant-tree-node-content-wrapper {
		white-space: nowrap;
	}
}

// ant中滚动条样式
.rc-virtual-list-scrollbar-show {
	width: 3px !important;
	.rc-virtual-list-scrollbar-thumb {
		background: #aab3af !important;
	}
}
.ant-tree-list-scrollbar {
	width: 3px !important;
	.ant-tree-list-scrollbar-thumb {
		background: #aab3af !important;
	}
}
.ant-tabs-nav {
	background-color: #edf1f2;
}
.ant-tabs-nav-wrap {
	background-color: #fff;
	padding: 0 10px;
	border-bottom: 1px solid #edf1f2;
}
.ant-select-dropdown {
	z-index: 20000;
}
.user-name-header {
	margin: 0 10px;
}
.iot-opt-view {
	.delete-span {
		color: #f5222d;
	}
}
.iot-opt-view > .iot-opt-more {
	color: #999999;
	padding-right: 2px;
	.anticon-caret-down {
		margin-left: 0;
		color: #999999;
	}
}
.iot-opt-more-list {
	.ant-dropdown-menu-item {
		text-align: center;
		padding-left: 0;
		padding-right: 0;
		span {
			color: var(--themeColor);
			cursor: pointer;
			white-space: nowrap;
			display: inline-block;
			width: 100%;
			&.iot-opt-del {
				color: #dd2b2b;
			}
		}
	}
}
.ant-modal-body {
	padding-bottom: 5px !important;
	.__temp__page_wrapper {
		margin-bottom: 5px !important;
	}
}

@primary-color: #fd8820;