.connect-type-manager-modal {
	.ant-modal-content {
		border-radius: 4px;
		overflow: hidden;
	}
	.ant-modal-body {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		padding: 0;
	}
	.type-manager-header-text {
		margin-left: 10px;
	}
	.type-manager-body {
		width: 100%;
		padding: 20px;
	}
	.type-manager-header {
		padding: 0 20px;
		height: 45px;
		width: 100%;
		background-color: var(--themeColor);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		color: #fff;
	}
	.submit-btn {
		width: 100%;
		text-align: center;
		margin-bottom: 20px;
	}
	.check-type-manager-body-content {
		width: 100%;
		min-height: 330px;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		display: flex;
		align-items: flex-start;
		padding: 20px;
		& > button {
			max-width: 120px;
		}
		.type-table-left {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 340px;
			margin-right: 10px;
		}
		.type-table {
			position: relative;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			width: 100%;
			.cell {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 46%;
				// border: 1px solid #e0dfdf;
				.ant-select {
					width: 100%;
				}
				.ant-select-selector {
					width: 170px;
				}
			}
			.delete-icon {
				color: #dd2b2b;
				margin-left: 3px;
				cursor: pointer;
				margin-top: -25px;
			}
		}
		.type-table-body {
			width: 100%;
			height: 340px;
			overflow: auto;
		}
		::-webkit-scrollbar {
			display: none;
		}
	}
	.answer_time {
		display: flex;
		.answer_time_cell {
			margin-right: 10px;
		}
		.ant-input {
			width: 100%;
		}
	}
	.icon {
		color: red;
		margin-left: 3px;
	}
}

@primary-color: #fd8820;